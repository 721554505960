<template>
  <div id="order-show-page">
    <vs-alert :color="getOrderStatusColor(order.status)">
      <span :class="order.status === 'sent' ? 'text-white' : ''">Order is {{ order.status }}.</span>
    </vs-alert>
    <vs-row class="mt-4" v-if="$acl.check('operator')">
      <vs-button v-if="order.status==='pending'" class="ml-auto" @click.prevent="confirm('accept', order.code, order.uuid)">Accept</vs-button>
      <vs-button v-if="order.status==='pending'" color="danger" type="border" class="ml-4" @click.prevent="confirmReject(order.code, order.uuid)">Reject</vs-button>
      <vs-button v-if="order.status==='accepted'" color="primary" class="ml-auto" @click.prevent="sentOrder(order.uuid)">Sent</vs-button>
      <vs-button v-if="order.status==='sent'" color="primary" class="ml-auto" @click.prevent="confirm('deliver', order.code, order.uuid)">Delivered</vs-button>
      <vs-button v-if="order.status==='sent'" color="danger" class="ml-4" @click.prevent="confirmNotDeliver(order.code, order.uuid)">Not delivered</vs-button>
    </vs-row>
    <vs-row>
      <div class="vs-col w-full lg:w-1/2 lg:pr-4">
          <h3 class="mb-3">Order details</h3>
        <vx-card class="mt-6">
          <vs-table hoverFlat stripe :data="Object.keys(order)">
            <template slot="thead">
              <vs-th>Property</vs-th>
              <vs-th>Value</vs-th>
            </template>
            <template>
              <vs-tr>
                <vs-td><span class="uppercase font-medium">Code:</span></vs-td>
                <vs-td>#{{ order.code }}</vs-td>
              </vs-tr>
              <vs-tr>
                <vs-td><span class="uppercase font-medium">Total price:</span></vs-td>
                <vs-td><strong>{{ order.total_price }}TMT</strong></vs-td>
              </vs-tr>
              <vs-tr>
                <vs-td><span class="uppercase font-medium">Payment:</span></vs-td>
                <vs-td>{{ order.payment_type | capitalize }}</vs-td>
              </vs-tr>
              <vs-tr>
                <vs-td><span class="uppercase font-medium">Delivery type:</span></vs-td>
                <vs-td>{{ order.delivery_type | capitalize }}</vs-td>
              </vs-tr>
              <vs-tr>
                <vs-td><span class="uppercase font-medium">Delivery cost:</span></vs-td>
                <vs-td>{{ order.delivery_cost }}TMT</vs-td>
              </vs-tr>
              <vs-tr>
                <vs-td><span class="uppercase font-medium">Delivery time:</span></vs-td>
                <vs-td>{{ order.delivery_time }}</vs-td>
              </vs-tr>
              <vs-tr>
                <vs-td><span class="uppercase font-medium">Status:</span></vs-td>
                <vs-td><vs-chip :color="getOrderStatusColor(order.status)">{{ order.status | capitalize }}</vs-chip></vs-td>
              </vs-tr>
              <vs-tr>
                <vs-td><span class="uppercase font-medium">{{ order.status }}:</span></vs-td>
                <vs-td>{{ order.actionAt }}</vs-td>
              </vs-tr>
              <vs-tr>
                <vs-td><span class="uppercase font-medium">Created:</span></vs-td>
                <vs-td>{{ order.createdAt }}</vs-td>
              </vs-tr>
              <vs-tr>
                <vs-td><span class="uppercase font-medium">Is synced:</span></vs-td>
                <vs-td>{{ order.isSynced }}</vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vx-card>
      </div>
      <div class="vs-col w-full lg:w-1/2 lg:pl-4">
          <h4 class="mb-4">User details</h4>
        <vx-card class="mt-6">
          <vs-table hoverFlat stripe :data="Object.keys(order)">
            <template slot="thead">
              <vs-th>Property</vs-th>
              <vs-th>Value</vs-th>
            </template>
            <template>
              <vs-tr>
                <vs-td><span class="uppercase font-medium">ID:</span></vs-td>
                <vs-td>{{ order.userId }}</vs-td>
              </vs-tr>
              <vs-tr>
                <vs-td><span class="uppercase font-medium">Name:</span></vs-td>
                <vs-td>{{ order.user_name }}</vs-td>
              </vs-tr>
              <vs-tr>
                <vs-td><span class="uppercase font-medium">Phone:</span></vs-td>
                <vs-td>{{ order.user_phone }}</vs-td>
              </vs-tr>
              <vs-tr>
                <vs-td><span class="uppercase font-medium">Address:</span></vs-td>
                <vs-td>{{ order.address }}</vs-td>
              </vs-tr>
              <vs-tr>
                <vs-td><span class="uppercase font-medium">Rating:</span></vs-td>
                <vs-td>{{ order.rating }}</vs-td>
              </vs-tr>
              <vs-tr>
                <vs-td><span class="uppercase font-medium">User comments:</span></vs-td>
                <vs-td>{{ order.comment_by_user }}</vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <h4 class="mt-4 mb-3">Courier details</h4>
          <vs-table stripe :data="Object.keys(order)">
            <template slot="thead">
              <vs-th>Property</vs-th>
              <vs-th>Value</vs-th>
            </template>
            <template>
              <vs-tr>
                <vs-td><span class="uppercase font-medium">ID:</span></vs-td>
                <vs-td>{{ order.carrierId }}</vs-td>
              </vs-tr>
              <vs-tr>
                <vs-td><span class="uppercase font-medium">NOTE:</span></vs-td>
                <vs-td><span class="font-medium" :class="order.status === 'rejected' || order.status === 'not_delivered' ? 'text-danger' : ''">{{ order.note }}</span></vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </vx-card>
      </div>
      <div class="w-full mt-6">
          <h3 class="mb-4">Order items</h3>
        <vx-card>
          <vs-table hoverFlat ref="table" :data="orderItems" stripe>
            <template slot="thead">
              <vs-th class="w-3"></vs-th>
              <vs-th class="w-4">Image</vs-th>
              <vs-th>Code</vs-th>
              <vs-th>Barcode</vs-th>
              <vs-th>Name</vs-th>
              <vs-th>Price</vs-th>
              <vs-th>Quantity</vs-th>
              <vs-th>Total</vs-th>
              <vs-th>Actions</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr v-for="(tr, indextr) in data" :key="indextr" class="pt-3">
                <vs-td class="w-3 m-0 pr-0">
                  <feather-icon v-if="data[indextr].type === 'product'" icon="BoxIcon" svgClasses="stroke-current, w-3, h-3, hover:text-primary" />
                  <feather-icon v-else icon="ArchiveIcon" svgClasses="stroke-current, w-3, h-3, hover:text-primary" />
                </vs-td>
                <vs-td>
                  <div class="img">
                    <img v-if="(data[indextr].product_images && data[indextr].product_images.length) || data[indextr].collection_images && data[indextr].collection_images.length" :src="`${$baseUrl}/brands/${tr.uuid}.webp`" alt="logo">
                    <p v-else class="text-black m-1">No image!</p>
                  </div>
                </vs-td>
                <vs-td :data="data[indextr].code">
                  {{ data[indextr].code }}
                </vs-td>
                <vs-td :data="data[indextr].bar_code">
                  {{ data[indextr].bar_code }}
                </vs-td>
                <vs-td :data="data[indextr][`name_${$i18n.locale}`]">
                  {{ data[indextr][`name_${$i18n.locale}`] }}
                </vs-td>
                <template v-if="data[indextr].type === 'product'">
                  <vs-td :data="data[indextr]['Orderproducts'].price">
                    {{ data[indextr]['Orderproducts'].price }}TMT
                  </vs-td>
                  <vs-td :data="data[indextr]['Orderproducts'].quantity">
                    {{ data[indextr]['Orderproducts'].quantity }}
                  </vs-td>
                  <vs-td :data="data[indextr]['Orderproducts'].total_price">
                    {{ data[indextr]['Orderproducts'].total_price }}TMT
                  </vs-td>
                </template>
                
                <template v-else>
                  <vs-td :data="data[indextr]['Ordercollections'].price">
                    {{ data[indextr]['Ordercollections'].price }}TMT
                  </vs-td>
                  <vs-td :data="data[indextr]['Ordercollections'].quantity">
                    {{ data[indextr]['Ordercollections'].quantity }}
                  </vs-td>
                  <vs-td :data="data[indextr]['Ordercollections'].total_price">
                    {{ data[indextr]['Ordercollections'].total_price }}TMT
                  </vs-td>

                </template>

                <vs-td class="whitespace-no-wrap">
                  <!-- <feather-icon icon="EyeIcon" :svgClasses="['stroke-current', 'w-5', 'h-5', 'hover:text-primary' ]" title="View product details" @click.stop="$router.push({name: 'productShow', params: { id: tr.uuid }} )" /> -->
                  <!-- <feather-icon v-if="$acl.check('operator')" icon="EditIcon" :svgClasses="['stroke-current', 'w-5', 'h-5', 'hover:text-primary' ]" class="ml-2" title="Edit brand details" @click.stop="editData(tr, tr.type)" /> -->
                  <feather-icon v-if="$acl.check('operator') && order.status === 'pending'" icon="TrashIcon" :svgClasses="['stroke-current', 'w-5', 'h-5', 'hover:text-danger' ]" class="ml-2" title="Change brand status" @click.stop="deleteItem(tr['Orderproducts'].uuid, tr.type)" />
                  <!-- <feather-icon icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="deleteData(tr.uuid)" /> -->
                </vs-td>
              </vs-tr>
            </template>

          </vs-table>
        </vx-card>
      </div>
    </vs-row>
        <vs-popup :active.sync="activePrompt" variant="danger" :title="isRejecting ? 'Rejecting order' : 'Edit order'">
      <div class="con-exemple-prompt">
        
        <template v-if="isRejecting || isNotDelivered">
          <p class="mb-3">Please enter reason:</p>
          <vs-textarea v-validate="'required'" name="reason" v-model="note" placeholder="Reason" />
          <span class="text-danger text-sm" v-show="errors.has('reason')">{{ errors.first('reason') }}</span>
          <vs-row class="mt-6">
            <vs-button @click="rejectOrder" color="danger">Reject</vs-button>
            <vs-button @click="cancel" color="primary" type="border" class="ml-auto">Cancel</vs-button>
          </vs-row>
        </template>

        <template v-else>
          <v-select v-validate="'required'" label="name" :options="carriers" name="carrier" placeholder="Select one" v-model="selectedCarrier" :reduce="carrier => carrier.id" />
          <span class="text-danger text-sm" v-show="errors.has('carrier')">{{ errors.first('carrier') }}</span>
          <vs-row class="mt-24">
            <vs-button @click="submitSent">Sent</vs-button>
            <vs-button @click="cancel" color="danger" type="border" class="ml-auto">Cancel</vs-button>
          </vs-row>
        </template>

      </div>
    </vs-popup>
  </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
  components: {
    vSelect
  },
  data () {
    return {
      order: {
        order_products: [],
        order_collections: []
      },
      carriers: [],
      selectedCarrier: '',
      activePrompt: false,
      isRejecting: '',
      isNotDelivered: '',
      note: ''
    }
  },
  computed: {
    itemsData () {
      return this.order.order_products
    },
    orderItems () {
      let items = []
      items = items.concat(this.order.order_products.map(el => { return {...el, type: 'product'} }))
      items = items.concat(this.order.order_collections.map(el => { return {...el, type: 'collection'} }))
      return items
    }
  },
  methods: {
    async fetchData () {
      await this.$http.get(`/orders/items/${this.$route.params.id}`).then(res => {
        // console.log(res)
        this.order = res.data
      })
    },
    async fetchCarriers () {
      await this.$http.get('/carriers')
        .then(res => { this.carriers = res.data.data })
        .catch(err => (err))
    },
    deleteItem (uuid, type) {
      this.$http.patch('/orders/remove-item', { type, uuid })
        .then(() => {
          // console.log(res)
          this.fetchData()
        })
    },
    sentOrder (id) {
      this.activeOrder = id
      this.activePrompt = true
      this.isRejecting = false
      this.isNotDelivered = false
    },
    submitSent () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$http.patch(`/orders/edit/${this.activeOrder}`, {
            status: 'sent',
            carrierId: this.selectedCarrier
          }).then(() => {
            this.fetchData()
            this.activePrompt = false
            this.activeOrder = ''
            this.isRejecting = ''
          }).catch(err => (err))
        }
      })
    },
    confirmReject (code, id) {
      this.activeOrder = id
      this.isRejecting = code
      this.activePrompt = true
    },
    confirmNotDeliver (code, id) {
      this.activeOrder = id
      this.isNotDelivered = code
      this.activePrompt = true
    },
    confirm (e, code, uuid) {
      this.activeOrder = uuid
      const color = e === 'reject' || e === 'not_delivered' ? 'danger' : 'primary'
      const title = `Confirm ${e}`
      let text = ''
      let accept = ''
      let acceptText = ''
      if (e === 'accept') {
        text = `You are about to accept order ${code}`
        accept = this.acceptOrder
        acceptText = 'Accept'
      }
      if (e === 'deliver') {
        text = `You are about to deliver order ${code}`
        accept = this.deliverOrder
        acceptText = 'Submit'
      }
      this.$vs.dialog({ type: 'confirm', color, title, text, accept, acceptText })
    },
    acceptOrder () {
      this.$http.patch(`/orders/edit/${this.activeOrder}`, { status: 'accepted' })
        .then(() => { this.fetchData() })
        .catch(err => (err))
    },
    deliverOrder () {
      this.$http.patch(`/orders/edit/${this.activeOrder}`, { status: 'delivered' })
        .then(() => { this.fetchData() })
        .catch(err => (err))
    },
    rejectOrder () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$http.patch(`/orders/edit/${this.activeOrder}`, {
            status: this.isRejecting ? 'rejected' : 'not_delivered',
            note: this.note
          }).then(() => {
            this.fetchData()
            this.activePrompt = false
            this.activeOrder = ''
            this.isRejecting = ''
            this.note = ''
          }).catch(err => (err))
        }
      })
    },
    cancel () {
      this.activePrompt = false
      this.activeOrder = ''
      this.isRejecting = ''
    },
    getOrderStatusColor (status) {
      switch (status) {
      case 'pending':   return 'warning'
      case 'accepted': return 'primary'
      case 'sent': return 'grey'
      case 'delivered': return 'success'
      case 'rejected': return 'danger'
      case 'not_delivered':  return 'danger'
      default: return 'primary'
      }
    }
  },
  async created () {
    await this.fetchData()
    if (this.order.status === 'pending' || this.order.status === 'accepted') await this.fetchCarriers()
  }
}
</script>